import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// TRIO Components
import MainWindow from '../screens/MainWindow'
import IndexEvents from '../screens/events/Index.events'
import RoomsEvents from '../screens/events/Rooms.events'
import RoomsOptions from '../screens/events/RoomsOptions.events'
import PlayerEvents from '../screens/events/Player.events'


class Router extends React.Component {

    render() {
        const routers = createBrowserRouter([
            {
                element: <MainWindow />,
                children: [
                    {
                        path: "/",
                        element: <IndexEvents />,
                    },
                    {
                        path: "/event/:eventId",
                        element: <RoomsEvents />,
                    },
                    {
                        path: "/event/:eventId/:roomId",
                        element: <RoomsOptions />,
                    },
                    {
                        path: "/event/:eventId/:roomId/player",
                        element: <PlayerEvents />,
                    },
                ]
            }
        ]);
        return (<RouterProvider router={routers} />)
    }
}

export default Router
