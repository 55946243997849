import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { 
    Box,
    Link,
    Typography,
} from '@mui/material'

// Adapters
import { getEventRoomsAvailable } from '../../adapters/Events.adapter'

// Interfaces
import { IEventRoom, IEventSingular } from './Interfaces.events'

// TRIO Components
import EventRoomCard from '../components/EventRoomCard'
import Loading from '../components/Loading'
import { mainStyle } from '../../styles/Index.Styles'
import Header from '../components/Header'

import { SELECTED_DAY } from '../../constants/day'

export default function RoomsEvents() {
    const { eventId } = useParams()

    const [eventDurationDays, setEventDurationDays] = useState(0)
    const [eventInfo, setEventInfo] = useState<IEventSingular | null>(null)
    const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false)
    const [selectedDayRooms, setSelectedDayRooms] = useState<IEventRoom[] | null>(null)

    const daySelected = localStorage.getItem(SELECTED_DAY)

    const intToWeekDay = (weekDay: number) => {
        const weekDays = ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXTA-FEIRA', 'SÁBADO']
        return weekDays[weekDay] || ''
    }

    const renderEventRoomCards = () => {
            if (!daySelected) {
                const dayCards = []
                if (eventInfo) {
                    const start = new Date(eventInfo.start_date)
                    for (let day = 0; day < eventDurationDays; day++) {
                        const currentDay = new Date(start)
                        currentDay.setDate(currentDay.getDate() + day * 1)
                        dayCards.push(
                            <Box
                                sx={[mainStyle.eventDayCard]}
                                key={`day-${day}-card`}
                                onClick={() => {
                                    localStorage.setItem(SELECTED_DAY, String(currentDay))
                                    setIsLoadingEvent(true)
                                }}
                            >
                                <Typography
                                    sx={[mainStyle.eventDayCardDate]}
                                >
                                    {`${currentDay.getDate()}/${currentDay.getMonth() + 1}`}
                                </Typography>
                                <Typography
                                    sx={[mainStyle.eventDayCardWeekDay]}
                                >
                                    {intToWeekDay(currentDay.getDay())}
                                </Typography>
                            </Box>
                        )
                    }
                }

                return (
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={[mainStyle.eventDayCardsView]}>{dayCards}</Box>
                    </Box>
                )
            } else {
                return (
                    <Box>
                        {eventInfo && selectedDayRooms?.map(e => (
                            <Link 
                                key={e.id}
                                href={(e.available ? `/event/${eventInfo.id}/${e.id}` : '#')}
                                color="inherit"
                                underline='none'
                            >
                                <EventRoomCard
                                    eventName={eventInfo.event_name}
                                    roomName={e.room_name}
                                    active={e.available}
                                    eventUuid={eventInfo.id}
                                    roomUuid={e.id}
                                    startDate={new Date(e.start_date)}
                                    stopDate={new Date(e.stop_date)}
                                    hasAd={e.ad_stream_link !== ''}
                                    hasCc={e.cc_stream_link !== '' || e.cc_us_stream_link !== ''}
                                    hasLb={e.lb_stream_link !== ''}
                                />
                            </Link>
                        ))}
                    </Box>
                )
        }
    }

    useEffect(() => {
        if (daySelected && eventInfo) {
            const selectedDay = new Date(daySelected).getDate()
            const filteredRooms = eventInfo.rooms.filter((room) => {
                const roomStart = new Date(room.start_date).getDate()
                const roomEnd = new Date(room.stop_date).getDate()

                if (roomStart <= selectedDay && roomEnd >= selectedDay) {
                    return true
                }

                return false
            })

            setSelectedDayRooms(filteredRooms)
        }

        setIsLoadingEvent(false)
    }, [eventInfo, daySelected])


    useEffect(() => {
        if (eventId) {
            const getEventInfo = async () => {
                try {
                    setIsLoadingEvent(true)
                    const { data } = await getEventRoomsAvailable(eventId)
                    setEventInfo(data)
                    setEventDurationDays(
                        new Date(data.stop_date).getDate() -
                        new Date(data.start_date).getDate() + 1
                    )                    
                } catch (error) {
                    console.error(error)
                } finally {
                    setIsLoadingEvent(false)
                }
            }
            getEventInfo()
        }
    }, [eventId])

    return (
        <Box sx={mainStyle.root}>
            <Header />
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                    <Box sx={{overflow: 'auto', height: '100%', padding: .2, borderRadius: 6}}>
                        <>
                            {isLoadingEvent && <Loading />}
                            {!isLoadingEvent && eventInfo && renderEventRoomCards()}
                            {!isLoadingEvent && eventInfo && eventInfo.rooms.length === 0 && (
                                <Typography sx={{padding: 4, display: 'flex', justifyContent: 'center'}}>
                                    Nenhuma Sala encontrada para este evento!
                                </Typography>
                            )}
                        </>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
